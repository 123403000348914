const CATEGORIES = {
  FOOD: '飲食',
  FUN: 'エンターテインメント',
  LIFE: 'Lifestyle/Kids',
  RENT: '家賃',
  EQ: 'Furniture and Equipment',
  GIFTS: 'Gifts',
  BIZ: 'Business Expense',
  MISC: 'その他',
}

const SUBCATEGORIES = {
  FOOD: {
    GROCERIES: '食料品',
    EATOUT: '外食',
    OTHER: 'その他',
  },
  FUN: {
    INTOWN: '市内',
    OVERSEAS: '海外旅行',
    TRAVEL: '旅行',
    OTHER: 'その他',
  },
  LIFE: {
    AONA: 'あおなちゃん',
    FASHION: 'Cosmetics & Fashion',
    OTHER: 'その他',
  },
  RENT: {
    RENT: '家賃',
    GAS: 'ガス',
    WATER: '水',
    ELECTRIC: '電気',
    OTHER: 'その他',
  },
  EQ: {
    FURNITURE: 'Furniture',
    HOUSEHOLD: 'Household Stuff',
    ELECTRONICS: 'Electronics',
    OTHER: 'その他',
  },
  GIFTS: {
    GIFTS: 'Gifts',
    OTHER: 'その他',
  },
  BIZ: {
    BIZ: 'Business Expense',
    TAX: 'Income Taxes',
    TAX2: 'Other Taxes',
    OTHER: 'その他',
  },
  MISC: {
    WEDDING: 'Wedding',
    CLINIC: 'Health & Welness',
    OTHER: 'その他',
  }
}

export {
  SUBCATEGORIES,
  CATEGORIES,
}