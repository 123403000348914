import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import './App.css'
import PleaseSignIn from './components/PleaseSignIn' 
import ReceiptForm from './components/ReceiptForm'
import ReportForm from './components/ReportForm'
import ThankYou from './components/ThankYou'

const App: React.FC = () => {
  return (
    <div className='App'>
      <header className='App-header'>
        Fin!
      </header>
      <Router>
        <Switch>
          <Route path="/thanks">
            <ThankYou></ThankYou>
          </Route>
          <Route path="/">
            <PleaseSignIn>
              <ReceiptForm></ReceiptForm>
              <ReportForm></ReportForm>
            </PleaseSignIn>
          </Route>
        </Switch>
      </Router>
    </div>
  )
}

export default App
