import React from 'react'

const storeSuggestions = [
  { key: 'iro iro 1 month'   , val: '色々　1ヶ月分'            },
  { key: 'sakura'            , val: 'Sakura'            },
  { key: 'keio store'        , val: 'Keio Store'        },
  { key: 'clinic'            , val: 'Clinic'            },
  { key: 'saint-germain'     , val: 'Saint-Germain'     },
  { key: 'ikinari steak'     , val: 'いきなりステーキ！'         },
  { key: 'inageya'           , val: 'いなげや'              },
  { key: 'tokyo gas'         , val: 'Tokyo Gas'         },
  { key: 'odango'            , val: 'お団子'               },
  { key: 'maza bokujo'       , val: 'マザー牧場'             },
  { key: 'lawson'            , val: 'Lawson'            },
  { key: 'greengeeks'        , val: 'GreenGeeks'        },
  { key: 'starbucks'         , val: 'スターバックス'        },
  { key: 'tullys'         , val: 'Tullys'        },
  { key: 'bel lino'          , val: 'ベルリーノ'           },
  { key: 'yume wine terrace' , val: 'Yume Wine Terrace' },
  { key: 'vietnam yadai fuchu' , val: 'ベトナム屋台' },
  { key: 'kisoji'            , val: '木曽路'        },
  { key: 'seijo ishii'            , val: '成城石井'        },
  { key: 'shimachu'            , val: 'Shimachu'        },
  { key: 'sizzler'            , val: 'Sizzler'        },
  { key: 'daiso'            , val: 'ダイソー'        },
  { key: 'decencia'            , val: 'Decencia'        },
  { key: 'ozeki'            , val: 'Ozeki'        },
  { key: 'coop'            , val: 'コープ'        },
  { key: 'ymobile'            , val: 'Ymobile'        },
  { key: 'otoya'            , val: '大戸屋'        },
]

export const getSuggestions = value => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  const storeFilter = ss => ss.key.indexOf(value.toLowerCase()) !== -1

  return inputLength === 0
    ? []
    : storeSuggestions.filter(storeFilter).map(s => s.val);
};

export const getSuggestionValue = suggestion => suggestion;

export const renderSuggestion = suggestion => (
  <div className="suggestions">
    {suggestion}
  </div>
);

