import React from 'react'
import Table from 'react-bootstrap/Table'
import './ReportForm.css'
import data from '../data'

const currencyFormat = (p:string, cat:string) => {
  const total = data.reduce((a,c) => c.period===p&&(c.cat===cat||cat==='TOTAL')?a+c.amt:a, 0)
  return '￥' + total.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const periods = [...new Set(data.map(d => d.period))] 
  .sort((a,b)=>a>b?-1:1)

const ReportForm: React.FC = () => {
  return (
    <>
      <h1>REPORT!</h1>
      <Table responsive>
        <thead>
          <tr>
            <th>Period</th>
            <th>飲食</th>
            <th>エンターテインメント</th>
            <th>Lifestyle/Kids</th>
            <th>家賃</th>
            <th>Furniture and Equipment</th>
            <th>Gifts</th>
            <th>Business Expense</th>
            <th>その他</th>
            <th>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          {periods.map(per => (
            <tr>
              <td>{per}</td>
              <td>{currencyFormat(per, 'FOOD')}</td>
              <td>{currencyFormat(per, 'FUN')}</td>
              <td>{currencyFormat(per, 'LIFE')}</td>
              <td>{currencyFormat(per, 'RENT')}</td>
              <td>{currencyFormat(per, 'EQ')}</td>
              <td>{currencyFormat(per, 'GIFTS')}</td>
              <td>{currencyFormat(per, 'BIZ')}</td>
              <td>{currencyFormat(per, 'MISC')}</td>
              <td>{currencyFormat(per, 'TOTAL')}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default ReportForm
