export default class Receipt {
  id: string
  amt: number
  cur: string
  jpyXr: number
  mm: number
  yyyy: number
  cat: string
  sub: string
  who: string
  store: string
  desc: string

  updateYYYYMM = () => {
    this.mm = parseInt(this.id.substring(4,6))
    this.yyyy = parseInt(this.id.substring(0,4))    
  }

  constructor(o?: Receipt) {
    if (o) {
      this.id = o.id
      this.amt = o.amt
      this.cur = o.cur
      this.jpyXr = o.jpyXr
      this.mm = o.mm
      this.yyyy = o.yyyy
      this.cat = o.cat
      this.sub = o.sub
      this.who = o.who
      this.store = o.store
      this.desc = o.desc
    } else {
      const d = new Date()
      const adj = (-d.getTimezoneOffset()/60)
      d.setHours(d.getHours() + adj)
      this.id = d.toISOString()
        .replace(/-|T|:|Z|\./g, '')
        .substring(0,15)
      this.amt = 0
      this.cur = 'JPY'
      this.jpyXr = 1
      this.updateYYYYMM()
      this.cat = 'MISC'
      this.sub = 'OTHER'
      this.who = 'KWA'
      this.store = ''
      this.desc = ''
    }
  }
}