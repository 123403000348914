import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import './ThankYou.css'


const ThankYou: React.FC = () => {
  const [ src, setSrc ] = useState('/images/arigatouGirl.resized.jpg')

  useEffect(() => {
    const r = Math.random()
    if (r <= .3) {
      setSrc('/images/arigatouMan.resized.jpg')
    } else if (r <= .6) {
      setSrc('/images/arigatouWoman.resized.jpg')
    }
  }, []);

  return (
    <section>
      <h3>ありがとうございます！</h3>
      <Image src={src} fluid />
      <div>
        <Link to="/">
          <Button>はい！</Button>
        </Link>
      </div>
    </section>
  )
}

export default ThankYou
