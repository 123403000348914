import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import { FaDoorClosed } from 'react-icons/fa'
import { Form, InputGroup, FormControl } from 'react-bootstrap'
import './PleaseSignIn.css'


interface Props {
  children: JSX.Element[] | JSX.Element
}

const PleaseSignIn: React.FC<Props> = ({children}): any => {
  const history = useHistory()

  const [user, setUser] = useState('')
  const [pw, setPw] = useState('')
  const [auth, setAuth] = useState('')

  useEffect(() => {
    const savedUser = localStorage.getItem('kwadevfinuser') || ''
    console.log('savedUser', savedUser)
    if (savedUser) {
      setAuth(savedUser)
    }

    return () => {
      setAuth('')
    };
  }, []);

  const tryLogIn = () => {
    setAuth('kwa')
    if (user==='kwa' || user==='kanako') {
      if (pw === '0204Itsumo1116') {
        setAuth(user)
      }
    }
  }

  // render
  if (auth) {
    return (
      <>
        { children }
      </>
    )
  }
  return (
    <>
      <div>
        <p>Please Sign In before Continuing</p>
      </div>

      <Form>

        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text>@</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder="だれ？"
            aria-label="Username"
            defaultValue={user}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setUser(evt.target.value)}
          />
        </InputGroup>

        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text>💖</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder="タレ"
            type="password"
            aria-label="Username"
            defaultValue={pw}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setPw(evt.target.value)}
          />
        </InputGroup>

        <Button size="lg" onClick={tryLogIn}>
          <FaDoorClosed />
        </Button>
      </Form>
    </>
  )
}

export default PleaseSignIn