import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Resizer from 'react-image-file-resizer'
import axios from 'axios'
import { FaSpinner } from 'react-icons/fa'
import Autosuggest from 'react-autosuggest'

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Dropdown from 'react-bootstrap/Dropdown'
import Col from 'react-bootstrap/Col'

import * as suggest from './suggestions'
import './ReceiptForm.css'
import Recept from '../models/Receipt'
import {
  CATEGORIES,
  SUBCATEGORIES,
} from '../const'




const ReceiptForm: React.FC = () => {
  const history = useHistory()

  const [receipt, setReceipt] = useState(new Recept())
  const [amt, setAmt] = useState(receipt.amt)
  const [cur, setCur] = useState(receipt.cur)
  const [who, setWho] = useState(receipt.who)
  const [cat, setCat] = useState(receipt.cat)
  const [sub, setSub] = useState(receipt.sub)
  const [filename, setFilename] = useState('')
  const [file, setFile] = useState(null)
  const [preview, setPreview] = useState('')

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const [hideTime, setHideTime] = useState(true)
  const [suggestions, setSuggestions] = useState([])

  useEffect(() => {
    const savedUser = localStorage.getItem('kwadevfinuser') || ''
    console.log('savedUser', savedUser)
    if (savedUser) {
      setWho(savedUser)
    }

    return () => {
      setWho('')
    };
  }, []);

  const setValue = (key: string, val: any) => {
    // set receipt based on type
    if (key==='amt') {
      receipt[key] = parseFloat(val)
    } else {
      receipt[key] = val
    }

    // update state
    if (key==='amt') {
      setAmt(parseFloat(val))
    }
    if (key==='cur') {
      setCur(val)
    }
    if (key==='cur') {
      setCur(val)
    }
    if (key==='who') {
      setWho(val)
      localStorage.setItem('kwadevfinuser', val);
    }
    if (key==='cat') {
      setCat(val)
      const firstKey = Object.keys(SUBCATEGORIES[val])[0]
      console.log('firstKey', firstKey);
      receipt.sub = firstKey
      setSub(firstKey)
    }
    if (key==='sub') {
      setSub(val)
      console.log('sub set to', val)
    }
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(suggest.getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const setTime = (key: string, val: string) => {
    // ex 20200115150923462
    // ex 
    let t = receipt.id.split(".")[0]
    const ext = receipt.id.split(".").length>1 ? receipt.id.split(".")[1] : ''
    console.log(receipt.id, t, ext)
    const v2 = val.length===1 ? `0${val}` : val
    if (key==='y') {
      t = `${val}${t.substring(4,12)}000`
      receipt.yyyy = parseInt(val)
    }
    if (key==='m') {
      t = `${t.substring(0,4)}${v2}${t.substring(6,12)}000`
      receipt.mm = parseInt(val)
    }
    if (key==='d') {
      t = `${t.substring(0,6)}${v2}${t.substring(8,12)}000`
    }
    if (key==='h') {
      t = `${t.substring(0,8)}${v2}${t.substring(10,12)}000`
    }
    if (key==='mn') {
      t = `${t.substring(0,10)}${v2}000`
    }
    receipt.id = t + (ext ? `.${ext}` : '')
  }

  const submitReceipt = () => {
    setLoading(true)
    setError('')

    try {
      let id, ext
      if (file) {
        const fns = filename.split('.')
        ext = fns[fns.length-1]
        id = receipt.id
        receipt.id = `${id}.${ext}`
        console.log(receipt)
      }
  
      // upload data
      axios.post('https://asia-northeast1-kwadev.cloudfunctions.net/fin-postdeets', receipt)
        .then(async response => {
          console.log('successfully uploaded data', response);
          // upload file
          if (file) {
            console.log('uploading file', file)
            const formData = new FormData();
            formData.append('file', file);
            axios.post(`/api/upload?id=${id}&ext=${ext}`, formData)
              .then(function(response){
                console.log('successfully uploaded', response)
                history.push('/thanks')
              })
              .catch(err => {
                alert('Uploaded the reciept but not the image :(')
                console.error('error uploading', err.response)
                setError(err.toString())
              })
          } else {
            history.push('/thanks')
          };
        })
        .catch(err => {
          try {
            setError(err.response.data.toString())
            alert(err.response.data)
          } catch{
            setError(err.toString())
          }
        })
        .finally(() => {
          setLoading(false)
        })

    } catch (err) {
      setError(err.toString())
      setLoading(false)
    }

  }

  const onFileChange = (e) => {
    console.log('file change', e)
    const upload_file = e.target.files[0];
    if (upload_file) {
      setFilename(upload_file.name)
      Resizer.imageFileResizer(
        upload_file,    // file
        250,            // maxWidth
        2000,           // maxHeight
        'jpeg',         // compressFormat
        50,             // quality
        0,              // rotation
        blob => {
          setFile(blob)
          console.log('upload_file', blob)
        },
        'blob'          // outputType
      );
      Resizer.imageFileResizer(
        upload_file,
        250,
        2000,
        'jpeg',
        50,
        0,
        uri => {
          setPreview(uri)
        },
        'uri'
      );
    }
  }

  const onChangeStore = (event: any, { newValue }) => {
    setReceipt({ ...receipt, store: newValue})
  }

  return (
    <div>
      <InputGroup className="mb-3">
        <Form.Label>Upload Receipt: (V{process.env.REACT_APP_VERSION})</Form.Label>
        <FormControl
          placeholder="Upload Receipt"
          type="file"
          name="input-file"
          onChange={onFileChange}
        />
        <p className="filename">{ filename }</p>
        {preview && <div className="preview">
            <img src={preview} />
          </div>
        }
        { hideTime && (
          <>
            <Button onClick={() => setHideTime(false)}>時間を選べましょう！</Button>
          </>
        )}
        { !hideTime && (
          <>
            {/* Year         */}
            <Form.Group as={Form.Row} className="attached">
              <Form.Label>年:</Form.Label>
              <Col sm={11}>
                <FormControl placeholder="YEAR" type="number" min={2010} defaultValue={receipt.id.substring(0,4)}
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setTime('y', evt.target.value)}
                />
              </Col>
            </Form.Group>
            {/* Month */}
            <Form.Group as={Form.Row} className="attached">
              <Form.Label>月:</Form.Label>
              <Col sm={11}>
                <FormControl placeholder="MONTH" type="number" min={1} max={12} defaultValue={receipt.id.substring(4,6)}
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setTime('m', evt.target.value)}
                />
              </Col>
            </Form.Group>
            {/* Day */}
            <Form.Group as={Form.Row} className="attached">
              <Form.Label>日:</Form.Label>
              <Col sm={11}>
                <FormControl placeholder="DAY" type="number" min={1} max={31} defaultValue={receipt.id.substring(6,8)}
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setTime('d', evt.target.value)}
                />
              </Col>
            </Form.Group>
            {/* Hour */}
            <Form.Group as={Form.Row} className="attached">
              <Form.Label>時:</Form.Label>
              <Col sm={11}>
                <FormControl placeholder="HOUR" type="number" min={0} max={23} defaultValue={receipt.id.substring(8,10)}
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setTime('h', evt.target.value)}
                />
              </Col>
            </Form.Group>
            {/* Minute */}
            <Form.Group as={Form.Row}>
              <Form.Label>分:</Form.Label>
              <Col sm={11}>
                <FormControl placeholder="MINUTE" type="number" min={0} max={59} defaultValue={receipt.id.substring(10,12)}
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setTime('mn', evt.target.value)}
                />
              </Col>
            </Form.Group>
          </>
        )}
        {/* Amount */}
        <Form.Group as={Form.Row} className="attached">
          <Form.Label>{ cur==='USD' ? '$' : '¥' }:</Form.Label>
          <Col sm={11}>
            <FormControl
              placeholder="AMT"
              name="amt"
              defaultValue={receipt.amt}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setValue(evt.target.name, evt.target.value)}
            />
          </Col>
        </Form.Group>
        {/* Currency */}
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            { cur }
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setValue('cur', 'JPY')}>JPY</Dropdown.Item>
            <Dropdown.Item onClick={() => setValue('cur', 'USD')}>USD</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {/* Category */}
        <Form.Label>Category:</Form.Label>
        <Dropdown>
          <Dropdown.Toggle variant="warning" id="dropdown-basic">
            { cat } ({ CATEGORIES[cat] })
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {
              Object.keys(CATEGORIES).map(key => {
                return (
                  <Dropdown.Item key={key} onClick={() => setValue('cat', key)}>{ CATEGORIES[key] }</Dropdown.Item>
                )
              })
            }
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown>
          <Dropdown.Toggle variant="info" id="dropdown-basic">
            { sub } ({ SUBCATEGORIES[cat][sub] })
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {
              Object.keys(SUBCATEGORIES[cat]).map(key => {
                return (
                  <Dropdown.Item key={key} onClick={() => setValue('sub', key)}>{ SUBCATEGORIES[cat][key] }</Dropdown.Item>
                )
              })
            }
          </Dropdown.Menu>
        </Dropdown>
        <Form.Label>Name:</Form.Label>
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            { who==='KWA'?'クワ':who==='KANA'?'かなこ':'???' }
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setValue('who', 'KWA')}>クワ</Dropdown.Item>
            <Dropdown.Item onClick={() => setValue('who', 'KANA')}>かなこ</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {/* store name 店名前 */}
        <Form.Label>Store:</Form.Label>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={suggest.getSuggestionValue}
          renderSuggestion={suggest.renderSuggestion}
          inputProps={{
            placeholder: 'Type store name',
            value: receipt.store,
            onChange: onChangeStore,
          }}
          style={{width:'100%'}}
        />
        {/* comment コメント */}
        <Form.Label>Comments:</Form.Label>
        <FormControl
          name="desc"
          defaultValue={receipt.desc}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setValue(evt.target.name, evt.target.value)}
        />
        {/* error msg */}
        { error && <div className="error">{error}</div> }
        <Button type='submit' size="lg" onClick={() => submitReceipt()} disabled={loading}>
          {loading && <FaSpinner className="spin" />}
          {!loading && <span>Send!</span>}
        </Button>
      </InputGroup>
    </div>
  )
}

export default ReceiptForm
