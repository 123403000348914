const data = [
  { period:'2020-01', cat:'FOOD', amt:50095 },
  { period:'2020-01', cat:'FUN', amt:84000 },
  { period:'2020-01', cat:'MISC', amt:53690 },
  { period:'2020-01', cat:'RENT', amt:6236 },
  { period:'2020-02', cat:'BIZ', amt:24 },
  { period:'2020-02', cat:'FOOD', amt:58153 },
  { period:'2020-02', cat:'FUN', amt:24603 },
  { period:'2020-02', cat:'MISC', amt:1200 },
  { period:'2020-02', cat:'RENT', amt:13328 },
  { period:'2020-03', cat:'BIZ', amt:354 },
  { period:'2020-03', cat:'EQ', amt:22383 },
  { period:'2020-03', cat:'FOOD', amt:104957 },
  { period:'2020-03', cat:'FUN', amt:5478 },
  { period:'2020-03', cat:'MISC', amt:109959 },
  { period:'2020-03', cat:'RENT', amt:15508 },
  { period:'2020-04', cat:'BIZ', amt:12140 },
  { period:'2020-04', cat:'FOOD', amt:92046 },
  { period:'2020-04', cat:'GIFTS', amt:17950 },
  { period:'2020-04', cat:'MISC', amt:97194 },
  { period:'2020-04', cat:'RENT', amt:6591 },
  { period:'2020-05', cat:'EQ', amt:13892 },
  { period:'2020-05', cat:'FOOD', amt:43559 },
  { period:'2020-05', cat:'GIFTS', amt:23944 },
  { period:'2020-05', cat:'MISC', amt:42600 },
  { period:'2020-06', cat:'BIZ', amt:128687 },
  { period:'2020-06', cat:'FOOD', amt:55240 },
  { period:'2020-06', cat:'FUN', amt:66850 },
  { period:'2020-06', cat:'GIFTS', amt:11000 },
  { period:'2020-06', cat:'MISC', amt:188966 },
  { period:'2020-06', cat:'RENT', amt:4372 },
  { period:'2020-07', cat:'BIZ', amt:48300 },
  { period:'2020-07', cat:'FOOD', amt:52933 },
  { period:'2020-07', cat:'FUN', amt:239970 },
  { period:'2020-07', cat:'MISC', amt:249851 },
  { period:'2020-07', cat:'RENT', amt:6620 },
  { period:'2020-08', cat:'BIZ', amt:28600 },
  { period:'2020-08', cat:'FOOD', amt:52304 },
  { period:'2020-08', cat:'FUN', amt:936854 },
  { period:'2020-08', cat:'MISC', amt:43743 },
  { period:'2020-08', cat:'RENT', amt:6826 },
  { period:'2020-09', cat:'FOOD', amt:126060 },
  { period:'2020-09', cat:'FUN', amt:263828 },
  { period:'2020-09', cat:'GIFTS', amt:10000 },
  { period:'2020-09', cat:'MISC', amt:44910 },
  { period:'2020-10', cat:'FOOD', amt:23710 },
  { period:'2020-10', cat:'MISC', amt:74400 },
]

export default data
